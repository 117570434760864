import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import type { Route } from 'antd/lib/breadcrumb/Breadcrumb'
import type { UseBreadcrumbProps } from './interface'

type RouteWithBreadcrumb = {
  [key in string]: Route
}

const useBreadcrumb: UseBreadcrumbProps = ({ isRootPage }) => {
  const { t } = useTranslation('menu')
  const location = useLocation()

  const routePathsWithBreadcrumb: RouteWithBreadcrumb = {
    general: {
      path: '/general',
      breadcrumbName: t('/general'),
    },
    security: {
      path: '/security',
      breadcrumbName: t('/security'),
    },
    setting: {
      path: '/setting',
      breadcrumbName: t('/setting'),
    },
  }

  const homePath: Route = routePathsWithBreadcrumb[location.pathname.split('/')[1]]

  const breadcrumbs = routePathsWithBreadcrumb

  let prevPath = ''

  const splitPathnameToBreadcrumbs: Route[] = !isRootPage
    ? location.pathname.split('/').map((pathname) => {
        const routeBreadcrumb: Route = breadcrumbs[pathname]

        if (pathname) {
          prevPath = `${prevPath}/${pathname}`
        }
        return routeBreadcrumb
      })
    : []

  return {
    routes: [homePath, ...splitPathnameToBreadcrumbs],
  }
}

export default useBreadcrumb
