import { useQuery } from '@apollo/client'
import { message } from 'antd'

import { getUserTypeEndpoint } from 'utils/user'
import { appLocalAccessToken } from 'utils/localService'

import QUERY_DOCUMENT from './getMyProfile'

import type { APIPayloadResponse, GraphQLServiceQueryHook } from 'graphQL/graphQL-service-hook'
import type { MyProfileAPIPayload } from './interface'

export interface MyProfileData {
  getMyProfile: APIPayloadResponse<MyProfileAPIPayload>
}

const useGetMyProfile: GraphQLServiceQueryHook<MyProfileData> = (options) => {
  return useQuery(QUERY_DOCUMENT, {
    context: {
      uri: getUserTypeEndpoint(appLocalAccessToken.get()),
    },
    onError(error) {
      message.error(error.message)
    },
    ...options,
  })
}

export default useGetMyProfile
