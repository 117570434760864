import React from 'react'
import { Space } from 'antd'
import styled from 'styled-components'

import LanguageSelector from 'components/LanguageSelector'
import UserMenuDropdown from './UserMenuDropdown'

const UserMenu: React.FC = () => {
  return (
    <Space size={16}>
      <ResponsiveLanguageSelector>
        <LanguageSelector />
      </ResponsiveLanguageSelector>
      <UserMenuDropdown />
    </Space>
  )
}

export default UserMenu

const ResponsiveLanguageSelector = styled.div`
  display: block;

  @media (max-width: 768px) {
    display: none;
  }
`
