import React from 'react'
import { PageHeader } from 'antd'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import useBreadcrumb from 'hooks/useBreadcrumb'

import type { Route } from 'antd/lib/breadcrumb/Breadcrumb'

interface PageTitleProps {
  isRootPage?: boolean
  title: string
  onBack?: () => void
  subtitle?: string
  extra?: React.ReactNode[]
}

const PageTitle: React.FC<PageTitleProps> = ({ isRootPage, title, onBack, subtitle, extra }) => {
  const history = useHistory()

  const { routes } = useBreadcrumb({
    isRootPage,
  })

  return (
    <PageHeader
      onBack={onBack}
      title={title}
      subTitle={subtitle}
      extra={extra}
      breadcrumb={{
        routes: [...routes],
        itemRender: renderRouteBreadcrumb,
      }}
      style={{
        padding: 0,
        margin: 0,
        borderBottom: '1px solid #dadada',
      }}
    />
  )

  function renderRouteBreadcrumb(route: Route) {
    if (route.breadcrumbName == null) {
      return null
    }

    return (
      <BreadcrumbItem className="breadcrumb-link" key={route.path} onClick={() => history.push(route.path)}>
        {route.breadcrumbName}
      </BreadcrumbItem>
    )
  }
}

export default PageTitle

const BreadcrumbItem = styled.span`
  cursor: pointer;

  &:first-child {
    color: #757575;
  }
`
