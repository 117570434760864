import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import FullScreenLoading from 'components/FullScreenLoading'

import { useAuthContext } from 'contexts/AuthProvider'

import { routePaths } from 'pages/routeConfig'

const SignOutPage: React.FC = () => {
  const auth = useAuthContext()
  const history = useHistory()

  useEffect(() => {
    auth.signOut()

    setTimeout(() => {
      window.open(routePaths.signIn, '_self')
    }, 1000)
  }, [auth, history])

  return <FullScreenLoading loadingText="กำลังออกจากระบบ..." />
}

export default SignOutPage
