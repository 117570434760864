type APIEndpointKey = 'core'

interface APIConfigPath {
  systemAdmin: string
  admin: string
  client: string
}

interface APIConfigStructure {
  host: string
  path: APIConfigPath
}

type APIConfig = {
  [key in APIEndpointKey]: APIConfigStructure
}

type API = {
  [key in APIEndpointKey]: APIConfigPath
}

const api: APIConfig = {
  core: {
    host: process.env.REACT_APP_API_CORE_HOST || 'http://localhost:5000',
    path: {
      systemAdmin: process.env.REACT_APP_API_CORE_PATH_SYSTEM_ADMIN || '/graphql/system-admin/v1.0',
      admin: process.env.REACT_APP_API_CORE_PATH_ADMIN || '/graphql/admin/v1.0',
      client: process.env.REACT_APP_API_CORE_PATH_CLIENT || '/graphql/client/v1.0',
    },
  },
}

const apiPath: API = {
  core: {
    systemAdmin: api.core.host + api.core.path.systemAdmin,
    admin: api.core.host + api.core.path.admin,
    client: api.core.host + api.core.path.client,
  },
}

export default apiPath
