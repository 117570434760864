import { gql } from '@apollo/client'

const REGISTER_EMAIL = gql`
  mutation registerEmail($input: INPUT_EMAIL_REGISTER_INPUT) {
    registerEmail(input: $input) {
      message
      code
      payload {
        email {
          value
        }
        phone {
          code
          value
        }
        attribute
      }
    }
  }
`

export default REGISTER_EMAIL
