import { gql } from '@apollo/client'

import { loginSchema } from 'graphQL/schema'

const LOGIN_EMAIL = gql`
  mutation loginEmail($email: String!, $password: String!, $authType: ENUM_AUTH_TYPE!, $redirectUrl: String) {
    loginEmail(email: $email, password: $password, authType: $authType, redirectUrl: $redirectUrl) {
      message
      code
      payload {
        ${loginSchema}
      }
    }
  }
`

export default LOGIN_EMAIL
