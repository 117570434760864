import React from 'react'

import useRole from 'hooks/useRole'

import { getAppDataFromStorage } from 'utils/app'

import General from 'components/General'

const GeneralPage: React.FC = () => {
  const app = getAppDataFromStorage()

  const role = useRole({
    credentialKey: app.credential.credentialKey,
  })

  return <General dataKeyList={role.dataKeyList} fieldList={role.userFields} />
}

export default GeneralPage
