import { useMutation } from '@apollo/client'
import { message } from 'antd'

import MUTATION_DOCUMENT from './loginEmail'

import type {
  APIPayloadResponse,
  AuthType,
  GraphQLServiceMutationHook,
  LoginAPIPayload,
} from 'graphQL/graphQL-service-hook'

interface LoginEmailData {
  loginEmail: APIPayloadResponse<LoginAPIPayload>
}

interface LoginEmailVars {
  email: string
  password: string
  authType: AuthType
  redirectUrl?: string | null
}

const useLoginEmail: GraphQLServiceMutationHook<LoginEmailData, LoginEmailVars> = (options) => {
  return useMutation(MUTATION_DOCUMENT, {
    onError(error) {
      message.error(error.message)
    },
    ...options,
  })
}

export default useLoginEmail
