import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'

import useQueryString from 'hooks/useQueryString'

import useGetAppByCredentialKey from 'graphQL/useGetAppByCredentialKey'

import FullScreenLoading from 'components/FullScreenLoading'

import { appLocalApp } from 'utils/localService'

import { authorizationKey } from 'config'
import { routePaths } from 'pages/routeConfig'

const withCredential =
  <T extends object>(WrappedComponent: React.ComponentType<T>): React.FC<T> =>
  (props) => {
    const appFromLocalStorage = JSON.parse(appLocalApp.get() as string)

    const query = useQueryString()

    const lineLiffStateQuery = new URLSearchParams(query.get('liff.state')?.toString())

    const credential = lineLiffStateQuery.get(authorizationKey.credential) || query.get(authorizationKey.credential)
    const authType = lineLiffStateQuery.get(authorizationKey.authType) || query.get(authorizationKey.authType)

    const [isLoading, setLoading] = useState(appFromLocalStorage?.credential?.credentialKey !== credential)

    const shouldSkip = credential == null || !isLoading

    const isAccessToSignInFromExternalWebsite = Boolean(credential && authType)

    if (!isAccessToSignInFromExternalWebsite && window.location.pathname !== routePaths.signIn) {
      return <Redirect to={routePaths.signIn} />
    }

    const appQuery = useGetAppByCredentialKey({
      skip: shouldSkip,
      variables: {
        credentialKey: String(credential),
      },
      onCompleted(resp) {
        appLocalApp.set(JSON.stringify(resp.getAppByCredential.payload))

        setLoading(false)
      },
    })

    const app = appQuery.data?.getAppByCredential.payload

    if (appQuery.loading || isLoading) {
      return <FullScreenLoading displayText />
    }

    if (!!app && appQuery.loading) {
      return <Redirect to={routePaths.signIn} />
    }

    return <WrappedComponent {...props} />
  }

export default withCredential
