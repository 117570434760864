import { useMutation } from '@apollo/client'
import { message } from 'antd'

import MUTATION_DOCUMENT from './verifyPasswordEmail'

import type { APIPayloadResponse, GraphQLServiceMutationHook } from 'graphQL/graphQL-service-hook'
import type { VerifyPasswordEmailInput } from './interface'

interface VerifyPasswordEmailData {
  verifyPasswordEmail: APIPayloadResponse<{ email: string }>
}

interface VerifyPasswordEmailVars {
  input: VerifyPasswordEmailInput
}

const useVerifyPasswordEmail: GraphQLServiceMutationHook<VerifyPasswordEmailData, VerifyPasswordEmailVars> = (
  options
) => {
  return useMutation(MUTATION_DOCUMENT, {
    onError(error) {
      message.error(error.message)
    },
    ...options,
  })
}

export default useVerifyPasswordEmail
