import { gql } from '@apollo/client'

const MASTER_DATA = gql`
  query getMasterData($input: INPUT_FIND_DATA) {
    getMasterData(input: $input) {
      message
      payload {
        parentKey
        dataKey
        locale
        text
        attribute
      }
    }
  }
`

export default MASTER_DATA
