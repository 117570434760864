import React from 'react'
import { BrowserRouter, Route, Switch, Redirect, RouteProps } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'

import withCredential from 'middlewares/withCredential'
import withAuth from 'middlewares/withAuth'

import { useAuthContext } from 'contexts/AuthProvider'

import { checkTokenExpired } from 'utils/token'
import { appLocalAccessToken } from 'utils/localService'

import SignOutPage from './signOut'

import {
  nonRequiredExternalAuthenticated,
  nonRequiredAuthenticationRoutes,
  privateRoutes,
  routePaths,
} from './routeConfig'

interface PrivateRouteProps extends RouteProps {
  isLoggedIn?: boolean
}

const PageRouter: React.FC = () => {
  const auth = useAuthContext()

  const isLoggedIn = auth.isLoggedIn || !checkTokenExpired(appLocalAccessToken.get())

  return (
    <BrowserRouter>
      <QueryParamProvider ReactRouterRoute={Route}>
        <Switch>
          {nonRequiredExternalAuthenticated.map((route) => {
            return <NonPrivateExternalAuthenticatedRoute {...route} />
          })}

          {nonRequiredAuthenticationRoutes.map((route) => {
            return <NonPrivateRoute {...route} />
          })}

          {privateRoutes.map((route) => {
            return <PrivateRoute {...route} isLoggedIn={isLoggedIn} />
          })}

          <Route path={routePaths.signOut} component={SignOutPage} />

          <PrivateRoute path="/" render={() => <Redirect to={routePaths.general} />} exact />
        </Switch>
      </QueryParamProvider>
    </BrowserRouter>
  )
}

export default PageRouter

const PrivateRoute: React.FC<PrivateRouteProps> = withAuth(({ isLoggedIn, ...props }) => <Route {...props} />)

const NonPrivateRoute: React.FC<RouteProps> = (props) => <Route {...props} />

const NonPrivateExternalAuthenticatedRoute: React.FC<RouteProps> = withCredential((props) => <Route {...props} />)
