import { useQuery } from '@apollo/client'

import QUERY_DOCUMENT from './getRoleRegistable'

import type { APIPayloadResponse, GraphQLServiceQueryHook } from 'graphQL/graphQL-service-hook'
import type { RoleRegistableAPIPayload } from './interface'

interface RoleRegisableData {
  getRoleRegisable: APIPayloadResponse<RoleRegistableAPIPayload>
}

interface RoleRegisableVars {
  roleKey?: string | null
}

const useGetRoleRegistable: GraphQLServiceQueryHook<RoleRegisableData, RoleRegisableVars> = (options) => {
  return useQuery(QUERY_DOCUMENT, {
    ...options,
  })
}

export default useGetRoleRegistable
