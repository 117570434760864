import { useMutation } from '@apollo/client'
import { message } from 'antd'

import MUTATION_DOCUMENT from './resetPasswordEmail'

import type { APIPayloadResponse, GraphQLServiceMutationHook } from 'graphQL/graphQL-service-hook'
import type { ResetPasswordEmailAPIPayload } from './interface'

interface ResetPasswordEmailData {
  resetPasswordEmail: APIPayloadResponse<ResetPasswordEmailAPIPayload>
}

interface ResetPasswordEmailVars {
  email: string
}

const useResetPasswordEmail: GraphQLServiceMutationHook<ResetPasswordEmailData, ResetPasswordEmailVars> = (options) => {
  return useMutation(MUTATION_DOCUMENT, {
    onError(error) {
      message.error(error.message)
    },
    ...options,
  })
}

export default useResetPasswordEmail
