import { useMutation } from '@apollo/client'
import { message } from 'antd'

import MUTATION_DOCUMENT from './registerEmail'

import type { APIPayloadResponse, GraphQLServiceMutationHook } from 'graphQL/graphQL-service-hook'
import type { MyProfileAPIPayload } from 'graphQL/useGetMyProfile/interface'
import type { RegisterInputData } from './interface'

interface RegisterEmailData {
  registerEmail: APIPayloadResponse<MyProfileAPIPayload>
}

interface RegisterEmailVars {
  input: RegisterInputData
}

const useRegisterEmail: GraphQLServiceMutationHook<RegisterEmailData, RegisterEmailVars> = (options) => {
  return useMutation(MUTATION_DOCUMENT, {
    onError(error) {
      message.error(error.message)
    },
    ...options,
  })
}

export default useRegisterEmail
