import { gql } from '@apollo/client'

import { applicationSchema, credentialSchema } from 'graphQL/schema'

const GET_APP_BY_CREDENTIAL = gql`
  query getAppByCredential($credentialKey: String!) {
    getAppByCredential(credentialKey: $credentialKey) {
      message
      code
      payload {
        app {
          ${applicationSchema}
        }
        credential {
          ${credentialSchema}
        }
      }
    }
  }
`

export default GET_APP_BY_CREDENTIAL
