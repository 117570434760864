import { gql } from '@apollo/client'

const MY_PROFILE = gql`
  query {
    getMyProfile {
      message
      code
      payload {
        email {
          value
        }
        phone {
          code
          value
        }
        attribute
      }
    }
  }
`

export default MY_PROFILE
