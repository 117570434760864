import { ApolloClient, InMemoryCache, createHttpLink, ApolloLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { message } from 'antd'

import REFRESH_ACCESS_TOKEN from 'graphQL/useRefreshAccessToken/refreshAccessToken'

import { getAppDataFromStorage } from 'utils/app'

import { appLocalAccessToken, appLocalLocale, appLocalRefreshToken, localeCode } from 'utils/localService'
import { getUserTypeEndpoint } from 'utils/user'

import api from './api'

const endpointContext = (url: string | undefined) => {
  if (url) {
    return url
  }

  return api.core.systemAdmin
}

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors) {
    const locale = appLocalLocale.get()

    graphQLErrors.forEach(async (error) => {
      switch (error.extensions?.code) {
        case 'TOKEN_IS_EXPIRED':
          const client = new ApolloClient({
            link: createHttpLink({
              uri: getUserTypeEndpoint(appLocalAccessToken.get()),
            }),
            cache: new InMemoryCache({
              addTypename: false,
            }),
          })

          const refreshAccessTokenResp = await client.mutate({
            mutation: REFRESH_ACCESS_TOKEN,
            variables: {
              refreshToken: appLocalRefreshToken.get(),
            },
          })

          const { accessToken, refreshToken } = refreshAccessTokenResp.data.refreshAccessToken.payload

          appLocalAccessToken.set(accessToken)
          appLocalRefreshToken.set(refreshToken)

          const oldHeaders = operation.getContext().headers

          operation.setContext({
            headers: {
              ...oldHeaders,
              authorization: `Bearer ${accessToken}`,
            },
          })

          return forward(operation).subscribe((observer) => {
            if (!observer.errors) {
              message.success(
                locale === localeCode.enUS
                  ? 'You have been updated profile information successfully'
                  : 'ท่านได้อัพเดทข้อมูลโปรไฟล์สำเร็จเรียบร้อย'
              )
            }
          })
        default:
          return
      }
    })
  }

  if (networkError) {
    console.log(`[Network error]: ${networkError}`)

    if (typeof networkError === 'string' && /401/.test(networkError)) {
      console.log('Network error 401, do something')
    }
  }
})

const authLink = setContext(async (_, { headers }) => {
  const app = getAppDataFromStorage()
  const accessToken = appLocalAccessToken.get()
  const locale = appLocalLocale.get()

  return {
    headers: {
      authorization: accessToken && `Bearer ${accessToken}`,
      credentialKey: app?.credential?.credentialKey || '',
      locale,
      ...headers,
    },
  }
})

const createLink = (httpLink: ApolloLink) => ApolloLink.from([errorLink, authLink, httpLink])

export function initializeApolloClient() {
  return new ApolloClient({
    link: createLink(
      createHttpLink({
        uri: (option) => endpointContext(option.getContext().uri),
      })
    ),
    cache: new InMemoryCache({
      addTypename: false,
    }),
  })
}

const apolloClient = initializeApolloClient()

export default apolloClient
