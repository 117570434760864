import React from 'react'
import dayjs from 'dayjs'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import BackButton from './BackButton'
import FullWidthSpace from './FullWidthSpace'
import LanguageSelector from './LanguageSelector'

interface RightPanelSignInProps {
  title: string
  copyright?: string
  isRootPage?: boolean
}

const RightPanelSignIn: React.FC<RightPanelSignInProps> = ({ title, copyright, isRootPage, children }) => {
  const { t } = useTranslation('signIn')
  const history = useHistory()

  const onBack = () => {
    history.goBack()
  }

  return (
    <RightPanelSignInWrapper>
      {!isRootPage && <BackButton onBack={onBack} />}
      <ApplicationNameTitle>{title}</ApplicationNameTitle>
      <FullWidthSpace size={24} direction="vertical">
        {children}
      </FullWidthSpace>
      <div style={{ marginTop: 24, textAlign: 'center' }}>
        <FullWidthSpace size={16} direction="vertical">
          <LanguageSelector />
          <CopyrightText>
            {t('copyrightText')} © {dayjs().format('YYYY')} {copyright}
          </CopyrightText>
        </FullWidthSpace>
      </div>
    </RightPanelSignInWrapper>
  )
}

export default RightPanelSignIn

const RightPanelSignInWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  padding: 80px 120px;
  overflow: auto;

  @media (max-width: 1500px) {
    margin: 0 auto;

    padding: 80px 50px;

    max-width: 960px;
  }

  @media (max-width: 768px) {
    margin: 0 auto;

    padding: 80px 16px;
  }
`

const ApplicationNameTitle = styled.span`
  font-size: 45px;
  font-family: 'Poppins Bold', sans-serif;
  color: var(--app-primary);
  text-align: center;
  margin-bottom: 24px;
`

const CopyrightText = styled.span`
  color: #7e7e7e;
  text-align: center;
`
