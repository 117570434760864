import React from 'react'
import styled from 'styled-components'
import { Divider } from 'antd'
import { useTranslation } from 'react-i18next'

import signInBanner from 'assets/images/sign-in-banner.png'

interface LeftPanelSignInProps {
  title?: string
  subtitle?: string
  description?: string
  extra?: React.ReactNode
}

const LeftPanelSignIn: React.FC<LeftPanelSignInProps> = ({ title, subtitle, description, extra }) => {
  const { t } = useTranslation('platform')

  return (
    <LeftPanelSignInWrapper bannerImage={signInBanner}>
      <AdminPanelTitle className="bold">{title || t('title')}</AdminPanelTitle>
      <AdminPanelSubtitle className="medium">{subtitle || t('subtitle')}</AdminPanelSubtitle>
      <AdminPanelDescription>{description || t('description')}</AdminPanelDescription>

      {extra && (
        <>
          <Divider />
          {extra}
        </>
      )}
    </LeftPanelSignInWrapper>
  )
}

export default LeftPanelSignIn

const LeftPanelSignInWrapper = styled.div<{ bannerImage: string }>`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 200px 0 100px;
  background-image: ${({ bannerImage }) => `url(${bannerImage})`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  box-shadow: inset 0 0 0 2000px rgba(4, 15, 25, 0.8);
  overflow: hidden;

  & > * {
    color: #fff;
  }
`

const AdminPanelTitle = styled.span`
  font-size: 60px;
`

const AdminPanelSubtitle = styled.span`
  font-size: 36px;
`

const AdminPanelDescription = styled.span`
  font-size: 16px;
  color: #ffd803;
`
