import React from 'react'
import { Drawer } from 'antd'
import { useTranslation } from 'react-i18next'

import MenuList from 'components/Menu'
import LanguageSelector from '../LanguageSelector'

import type { MainMenuDrawerProps } from './interface'

const MainMenuDrawer: React.FC<MainMenuDrawerProps> = ({ currentRoute, defaultRoute, visible, onClose }) => {
  const { t } = useTranslation('menu')

  return (
    <Drawer
      title={t('title')}
      placement="left"
      onClose={onClose}
      visible={visible}
      bodyStyle={{ padding: 0 }}
      headerStyle={{
        padding: '17px 24px',
      }}
      footer={<LanguageSelector />}
    >
      <MenuList currentRoute={currentRoute} defaultRoute={defaultRoute} />
    </Drawer>
  )
}

export default MainMenuDrawer
