import { gql } from '@apollo/client'

const CHANGE_PASSWORD = gql`
  mutation changePassword($password: String!, $newPassword: String!, $confirmPassword: String!) {
    changePassword(password: $password, newPassword: $newPassword, confirmPassword: $confirmPassword) {
      message
      code
      payload {
        email {
          value
        }
        phone {
          code
          value
          verifyStatus
        }
        attribute
      }
    }
  }
`

export default CHANGE_PASSWORD
