import { gql } from '@apollo/client'

const VERIFY_INVITE_ORGANIZATION = gql`
  mutation verifyInviteOrganization($code: String!) {
    verifyInviteOrganization(code: $code) {
      payload {
        userId
        status
        email
        registered
      }
    }
  }
`

export default VERIFY_INVITE_ORGANIZATION
