import React from 'react'
import styled from 'styled-components'

interface ActionRowProps {
  leftContent?: React.ReactNode
  rightContent?: React.ReactNode
}

const ActionRow: React.FC<ActionRowProps> = ({ leftContent, rightContent }) => {
  return (
    <ActionRowWrapper>
      <LeftContentWrapper>{leftContent}</LeftContentWrapper>
      {rightContent && <RightContentWrapper>{rightContent}</RightContentWrapper>}
    </ActionRowWrapper>
  )
}

export default ActionRow

const ActionRowWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  &::after {
    content: '';
    clear: both;
    display: table;
  }
`

const LeftContentWrapper = styled.div`
  flex: 1 1 auto;
`

const RightContentWrapper = styled.div`
  margin-left: 16px;
`
