import React, { useState } from 'react'
import { message } from 'antd'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import useLoginEmail from 'graphQL/useLoginEmail'
import useResetPasswordEmail from 'graphQL/useResetPasswordEmail'

import CredentialApplicationSignIn from 'components/CredentialApplicationSignIn/CredentialApplicationSignIn'

import { useAuthContext } from 'contexts/AuthProvider'

import { appLocalApp } from 'utils/localService'

import useQueryString from 'hooks/useQueryString'

import { authorizationKey } from 'config'
import { routePaths } from 'pages/routeConfig'

export interface ForgotPasswordData {
  email: string
}

const CredentialApplicationSignInPage: React.FC = () => {
  const { t } = useTranslation('credentialApplicationSignIn')

  const [isShowForgotPasswordForm, setShowForgorPasswordForm] = useState(false)
  const [isResetPasswordDisabled, setResetPasswordDisabled] = useState(false)
  const [email, setEmail] = useState('')

  const auth = useAuthContext()
  const history = useHistory()
  const query = useQueryString()
  const redirectUrl = query.get(authorizationKey.redirectUrl)

  const app = JSON.parse(appLocalApp.get() as string)

  const [loginEmail, loginEmailResp] = useLoginEmail({
    onCompleted(resp) {
      const { token, authType, credentialKey, code, redirectUrl } = resp.loginEmail.payload

      auth.signIn(token)

      if (redirectUrl) {
        const callbackUrl = `${redirectUrl}?${authorizationKey.code}=${code}&${authorizationKey.authType}=${authType}&${authorizationKey.credential}=${credentialKey}`

        window.open(callbackUrl, '_self')
      } else {
        history.push(routePaths.general)
      }
    },
  })

  const [resetPassword, resetPasswordResp] = useResetPasswordEmail({
    onCompleted() {
      message.success(
        t('resetPasswordSuccessText', {
          email,
        })
      )

      setResetPasswordDisabled(true)
    },
    onError() {
      message.error(t('resetPasswordFailedText'))

      setResetPasswordDisabled(true)
    },
  })

  return (
    <CredentialApplicationSignIn
      app={app}
      isResetPasswordDisabled={isResetPasswordDisabled}
      isShowForgotPasswordForm={isShowForgotPasswordForm}
      loginEmailLoading={loginEmailResp.loading}
      onBackToSignInApplication={() => {
        setShowForgorPasswordForm(false)
        setResetPasswordDisabled(false)
      }}
      onFinish={(data) => {
        loginEmail({
          variables: {
            ...data,
            authType: redirectUrl ? 'AUTH_CODE' : 'AUTH_TOKEN',
            redirectUrl,
          },
          context: {
            headers: {
              credentialKey: app?.credential?.credentialKey,
            },
          },
        })
      }}
      onFinishResetPassword={(data) => {
        setEmail(data.email)
        resetPassword({
          variables: {
            email: data.email,
          },
          context: {
            headers: {
              credentialKey: app?.credential?.credentialKey,
            },
          },
        })
      }}
      onForgotPassword={() => {
        setShowForgorPasswordForm(true)
      }}
      resetPasswordLoading={resetPasswordResp.loading}
    />
  )
}

export default CredentialApplicationSignInPage
