import React from 'react'
import styled from 'styled-components'

import { useThemeContext } from 'contexts/ThemeProvider/ThemeProvider'

const Logo: React.FC = () => {
  const theme = useThemeContext()

  const { image } = theme.admin

  const navLogo = image?.navAuthLogo || image?.mainNavLogo
  const navCollpaseLogo = image?.navAuthCollapsedLogo || image?.mainNavLogoCollapsed

  const isHaveAnImage = Boolean(navLogo || navCollpaseLogo)

  return (
    <LogoWrapper>
      {isHaveAnImage ? (
        <Image src={theme.collapsed ? navCollpaseLogo : navLogo} alt={theme.admin.text?.navTitle} />
      ) : (
        <FallbackAppLogo>{renderFallbackText()}</FallbackAppLogo>
      )}
    </LogoWrapper>
  )

  function renderFallbackText() {
    if (theme.collapsed) {
      return 'A'
    }

    return 'Admin Panel'
  }
}

export default Logo

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  margin: 16px;
`

const Image = styled.img`
  width: auto;
  height: 32px;
`

const FallbackAppLogo = styled.span`
  color: #000;
`
