import React from 'react'
import { LeftOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

interface BackButtonProps {
  onBack: () => void
}

const BackButton: React.FC<BackButtonProps> = ({ onBack }) => {
  const { t } = useTranslation('backButton')

  return (
    <Tooltip title={t('title')}>
      <BackButtonArrowLeft style={{ fontSize: 36 }} onClick={onBack} />
    </Tooltip>
  )
}

export default BackButton

const BackButtonArrowLeft = styled(LeftOutlined)`
  position: absolute;
  top: 20px;
  left: 20px;

  @media (max-width: 768px) {
    top: 20px;
    left: 10px;
  }
`
