import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'

const StickAtBottom: React.FC = ({ children }) => {
  const containerEl = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const mainLayoutContent = document.getElementById('MainLayoutContent') as HTMLElement

    mainLayoutContent.style.paddingBottom = '136px'

    return () => {
      mainLayoutContent.removeAttribute('style')
    }
  }, [])

  return <Container ref={containerEl}>{children}</Container>
}

export default StickAtBottom

const Container = styled.div`
  position: fixed;
  z-index: 15;
  padding: 36px;
  right: 0;
  bottom: 0;
  width: 100%;
  background-color: #fafafa;
  box-shadow: 0px -5px 20px rgba(0, 0, 0, 0.1);
`
