import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { message } from 'antd'
import { useHistory } from 'react-router-dom'
import dayjs from 'dayjs'

import DefaultLayout from 'layouts/DefaultLayout'

import useRegisterEmail from 'graphQL/useRegisterEmail'
import useVerifyInviteOrganization from 'graphQL/useVerifyInviteOrganization'
import useUpdateMyProfile from 'graphQL/useUpdateMyProfile'

import apiPath from 'config/api'
import { authorizationKey, queryKey, thirdPartyChannelType } from 'config'
import { routePaths } from 'pages/routeConfig'

import useRole from 'hooks/useRole'
import useQueryString from 'hooks/useQueryString'

import RoleSelection from 'components/RoleSelection'
import RegisterForm from './RegisterForm'

import type { RegisterProps } from './interface'

const Register: React.FC<RegisterProps> = ({ app }) => {
  const { t } = useTranslation('platform')
  const history = useHistory()

  const [roleKey, setRoleKey] = useState<string | undefined>()

  const query = useQueryString()
  const code = query.get(authorizationKey.code)
  const roleKeyQuery = query.get('role_key')
  const credentialKey = query.get(authorizationKey.credential)
  const channelType = query.get(queryKey.channelType)

  const isThirdPartyRegister = channelType && thirdPartyChannelType.includes(channelType)

  const role = useRole({
    credentialKey,
    roleKey,
  })

  const [registerEmail, registerEmailResp] = useRegisterEmail({
    onCompleted() {
      if (code) {
        verifyInviteOrganization({
          variables: {
            code,
          },
        })
      } else {
        message.success(t('register:registerSuccessText'))

        history.push({
          pathname: routePaths.credentialApplicationSignIn,
          search: window.location.search,
        })
      }
    },
  })

  const [updateMyProfile, updateMyProfileResp] = useUpdateMyProfile({
    onCompleted() {
      message.success(t('register:registerSuccessText'))

      history.push({
        pathname: routePaths.liff,
        search: window.location.search,
      })
    },
  })

  const [verifyInviteOrganization] = useVerifyInviteOrganization({
    onCompleted() {
      message.success(t('verifyInviteOrganization:verifySuccessText'))

      history.push({
        pathname: routePaths.credentialApplicationSignIn,
        search: window.location.search,
      })
    },
    onError(error) {
      message.error(error.message)
    },
    context: {
      headers: {
        credentialKey,
      },
    },
  })

  return (
    <DefaultLayout loading={role.loading} platformName={app?.app.name || t('title')}>
      <RegisterForm
        key={roleKey}
        loading={registerEmailResp.loading || updateMyProfileResp.loading}
        dataKeyList={role.dataKeyList}
        fieldList={role.userFields}
        onFinish={(data) => {
          const { email, password, confirmPassword, ...attribute } = data

          const userAttribute = {
            ...attribute,
            dateOfBirth: dayjs(data.dateOfBirth).toISOString(),
          }

          const context = {
            uri: apiPath.core.client,
            headers: {
              credentialKey: app?.credential.credentialKey,
            },
          }

          if (isThirdPartyRegister) {
            updateMyProfile({
              variables: {
                input: {
                  attribute: userAttribute,
                },
              },
              context,
            })
          } else {
            registerEmail({
              variables: {
                input: {
                  email,
                  password,
                  confirmPassword,
                  attribute: userAttribute,
                  roleKey: roleKeyQuery || '',
                },
              },
              context,
            })
          }
        }}
      />
      {role.roleList.length > 1 && <RoleSelection {...role} onSelect={(value) => setRoleKey(value)} />}
    </DefaultLayout>
  )
}

export default Register
