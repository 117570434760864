import React from 'react'
import { Form, FormInstance, Input, Modal } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

import type { PasswordFormData } from './interface'
import HooraySubmitButton from 'components/HooraySubmitButton'

interface ChangePasswordModalProps {
  loading?: boolean
  formRef: React.MutableRefObject<FormInstance<PasswordFormData> | null>
  visible: boolean
  onFinish: (data: PasswordFormData) => void
  onCancel: () => void
}

const ChangePasswordModal: React.FC<ChangePasswordModalProps> = ({ loading, formRef, visible, onFinish, onCancel }) => {
  const { t } = useTranslation('changePasswordModal')

  return (
    <Modal
      title={t('title')}
      bodyStyle={{
        minWidth: '100%',
        maxWidth: '100%',
      }}
      centered
      visible={visible}
      closeIcon={<CloseOutlined onClick={onCancel} />}
      footer={false}
    >
      <Form
        ref={(node) => (formRef.current = node)}
        name="passwordModalChangerForm"
        labelAlign="left"
        layout="vertical"
        onFinish={onFinish}
      >
        <Form.Item colon={false} name="password" label={t('label.currentPassword.title')}>
          <Input.Password
            placeholder={t('global:inputPlaceholder', {
              placeholderName: t('label.currentPassword.title').toLowerCase(),
            })}
          />
        </Form.Item>

        <Form.Item colon={false} name="newPassword" label={t('label.newPassword.title')}>
          <Input.Password
            placeholder={t('global:inputPlaceholder', {
              placeholderName: t('label.newPassword.title').toLowerCase(),
            })}
          />
        </Form.Item>

        <Form.Item colon={false} name="confirmPassword" label={t('label.confirmNewPassword.title')}>
          <Input.Password placeholder={t('label.confirmNewPassword.placeholder')} />
        </Form.Item>

        <Form.Item>
          <HooraySubmitButton loading={loading} />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default ChangePasswordModal
