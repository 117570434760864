import React from 'react'
import { Form, Input, Select } from 'antd'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { onFilterSelect } from 'utils/antd'

import type { MasterDataSelection } from 'hooks/useMasterData/interface'
import type { UserField } from 'graphQL/useGetRoleRegistable/interface'
import type { Rule } from 'antd/lib/form'

interface PhoneNumberProps {
  field: UserField
  dialingCodeList: MasterDataSelection
}

const PhoneNumber: React.FC<PhoneNumberProps> = ({ field, dialingCodeList }) => {
  const { t } = useTranslation('generalForm')

  const ruleRequired: Rule = {
    required: true,
    message: t('global:requiredText'),
  }

  return (
    <Input.Group compact>
      <Form.Item
        noStyle
        name="phoneCode"
        wrapperCol={{ xs: 24, sm: 8, md: 8 }}
        rules={[
          {
            ...ruleRequired,
            required: field.required,
          },
        ]}
      >
        <PhoneCodeSelection
          showSearch
          filterOption={onFilterSelect}
          placeholder={t('global:inputPlaceholder', {
            placeholderName: t('label.phoneCode.title').toLowerCase(),
          })}
        >
          {Object.values(dialingCodeList).map((dialingCode) => {
            return (
              <Select.Option key={dialingCode.dataKey} value={dialingCode.dataKey}>
                {dialingCode.text}
              </Select.Option>
            )
          })}
        </PhoneCodeSelection>
      </Form.Item>
      <Form.Item
        noStyle
        name={field.key}
        rules={[
          {
            ...ruleRequired,
            required: field.required,
          },
        ]}
      >
        <PhoneNumberInput type="number" maxLength={10} placeholder={field.placeholder} />
      </Form.Item>
    </Input.Group>
  )
}

export default PhoneNumber

const PhoneCodeSelection = styled(Select)`
  &.ant-select.ant-select-lg {
    width: 30%;
  }

  @media (max-width: 576px) {
    &.ant-select.ant-select-lg {
      width: 100%;
      margin-bottom: 16px;
    }
  }
`

const PhoneNumberInput = styled(Input)`
  &.ant-input.ant-input-lg {
    width: 70%;
  }

  @media (max-width: 576px) {
    &.ant-input.ant-input-lg {
      width: 100%;
    }
  }
`
