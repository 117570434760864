import { useQuery } from '@apollo/client'
import { message } from 'antd'

import QUERY_DOCUMENT from './getAppByCredential'

import type { APIPayloadResponse, GraphQLServiceQueryHook } from 'graphQL/graphQL-service-hook'
import type { AppByCredentialKeyAPIPayload } from './interface'

interface AppByCredentialKeylData {
  getAppByCredential: APIPayloadResponse<AppByCredentialKeyAPIPayload>
}

interface AppByCredentialKeyVars {
  credentialKey: string
}

const useGetAppByCredentialKey: GraphQLServiceQueryHook<AppByCredentialKeylData, AppByCredentialKeyVars> = (
  options
) => {
  return useQuery(QUERY_DOCUMENT, {
    onError(error) {
      message.error(error.message)
    },
    ...options,
  })
}

export default useGetAppByCredentialKey
