import React from 'react'
import { useTranslation } from 'react-i18next'

import AuthenticatedLayout from 'layouts/AuthenticatedLayout'

import ContentCard from 'components/ContentCard'
import PageTitle from 'components/PageTitle'
import PasswordForm from './PasswordForm'

const Security: React.FC = () => {
  const { t } = useTranslation('security')

  return (
    <AuthenticatedLayout>
      <PageTitle isRootPage title={t('title')} />
      <ContentCard title={t('cardTitle.password')}>
        <PasswordForm />
      </ContentCard>
    </AuthenticatedLayout>
  )
}

export default Security
