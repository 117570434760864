import { gql } from '@apollo/client'

const RESET_PASSWORD_EMAIL = gql`
  mutation resetPasswordEmail($email: String!) {
    resetPasswordEmail(email: $email) {
      message
      code
      payload {
        email
      }
    }
  }
`

export default RESET_PASSWORD_EMAIL
