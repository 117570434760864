import { gql } from '@apollo/client'

import { applicationSchema, credentialSchema } from 'graphQL/schema'

const GET_APP_BY_EMAIL = gql`
  query getAppByEmail($email: String!) {
    getAppByEmail(email: $email) {
      message
      code
      payload {
        app {
          ${applicationSchema}
        }
        credential {
          ${credentialSchema}
        }
      }
    }
  }
`

export default GET_APP_BY_EMAIL
