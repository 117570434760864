import React from 'react'
import { Form, message } from 'antd'
import { useTranslation } from 'react-i18next'

import { useAuthContext } from 'contexts/AuthProvider'

import ContentCard from 'components/ContentCard'
import PageTitle from 'components/PageTitle'
import StickAtBottom from 'components/StickAtBottom'
import HooraySubmitButton from 'components/HooraySubmitButton'
import ActionRow from 'components/ActionRow'
import SystemForm from './SystemForm'
import GeneralForm from './GeneralForm'

import useMasterData from 'hooks/useMasterData'

import AuthenticatedLayout from 'layouts/AuthenticatedLayout'

import useGetMyProfile from 'graphQL/useGetMyProfile'
import useUpdateMyProfile from 'graphQL/useUpdateMyProfile'

import { commonFormProps } from 'utils/antd'

import type { GeneralProps } from './interface'
import dayjs from 'dayjs'

const General: React.FC<GeneralProps> = ({ dataKeyList, fieldList }) => {
  const { t } = useTranslation('general')

  const auth = useAuthContext()

  const myProfileQuery = useGetMyProfile()

  const profileData = myProfileQuery.data?.getMyProfile.payload
  const attribute = profileData?.attribute

  const { loading, masterDataList } = useMasterData({
    parentKeys: [...dataKeyList, 'DIALING_CODE', 'NAME_PREFIX'],
  })

  const [updateMyProfile, updateMyProfileResp] = useUpdateMyProfile({
    onCompleted(resp) {
      message.success(t('updateProfileSuccessText'))

      auth.onProfileImageChange(resp.updateMyProfile.payload.attribute?.profilePictureFileKey)

      myProfileQuery.refetch()
    },
  })

  return (
    <AuthenticatedLayout>
      <PageTitle isRootPage title={t('title')} />

      <Form
        {...commonFormProps}
        initialValues={{
          ...attribute,
          dateOfBirth: dayjs(attribute?.dateOfBirth),
          email: profileData?.email[0].value,
        }}
        labelAlign="left"
        onFinish={(data) => {
          const { email, ...attribute } = data

          updateMyProfile({
            variables: {
              input: {
                attribute,
              },
            },
          })
        }}
      >
        <ContentCard title={t('cardTitle.system')} loading={myProfileQuery.loading || loading}>
          {(!myProfileQuery.loading || !loading) && <SystemForm namePrefixList={masterDataList.NAME_PREFIX} />}
        </ContentCard>

        {fieldList.length > 0 && (
          <ContentCard title={t('cardTitle.general')} loading={myProfileQuery.loading || loading}>
            {(!myProfileQuery.loading || !loading) && (
              <GeneralForm fieldList={fieldList} masterDataList={masterDataList} />
            )}
          </ContentCard>
        )}

        <StickAtBottom>
          <ActionRow rightContent={<HooraySubmitButton loading={updateMyProfileResp.loading} />} />
        </StickAtBottom>
      </Form>
    </AuthenticatedLayout>
  )
}

export default General
