export const removeUndefinedObjectData = <T extends object>(obj: T): T => {
  const cloneObject = {
    ...obj,
  }

  Object.keys(cloneObject).forEach((key) => {
    const objectKey = key as keyof T

    if (cloneObject[objectKey] === undefined) {
      delete cloneObject[objectKey]
    }
  })

  return cloneObject
}
