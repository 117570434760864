import { useQuery } from '@apollo/client'
import { message } from 'antd'

import QUERY_DOCUMENT from './getAppByEmail'

import type { APIPayloadResponse, GraphQLServiceQueryHook } from 'graphQL/graphQL-service-hook'
import type { GetAppByEmailAPIPayload } from './interface'

interface GetAppByEmailData {
  getAppByEmail: APIPayloadResponse<GetAppByEmailAPIPayload[]>
}

interface GetAppByEmailVars {
  email: string
}

const useGetAppByEmail: GraphQLServiceQueryHook<GetAppByEmailData, GetAppByEmailVars> = (options) => {
  return useQuery(QUERY_DOCUMENT, {
    onError(error) {
      message.error(error.message)
    },
    ...options,
  })
}

export default useGetAppByEmail
