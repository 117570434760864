import { gql } from '@apollo/client'

const VERIFY_PASSWORD_EMAIL = gql`
  mutation verifyPasswordEmail($input: INPUT_VERIFY_PASSWORD_EMAIL) {
    verifyPasswordEmail(input: $input) {
      message
      code
      payload {
        email
      }
    }
  }
`

export default VERIFY_PASSWORD_EMAIL
