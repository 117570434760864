import React, { useRef, useState } from 'react'
import { Button, Form, FormInstance, message } from 'antd'
import dayjs from 'dayjs'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import useChangePassword from 'graphQL/useChangePassword'

import ChangePasswordModal from 'components/Security/ChangePasswordModal'

import { commonFormProps } from 'utils/antd'

import type { PasswordFormData } from './interface'

const PasswordForm: React.FC = () => {
  const { t } = useTranslation('passwordForm')

  const [visible, setVisible] = useState(false)

  const formRef = useRef<FormInstance<PasswordFormData> | null>(null)

  const [changePassword, changePasswordResp] = useChangePassword({
    onCompleted() {
      message.success(t('changePasswordSuccessText'))

      formRef.current?.resetFields()

      setVisible(false)
    },
  })

  return (
    <>
      <Form {...commonFormProps} name="passwordForm">
        <Form.Item
          label={t('label.currentPassword.title')}
          extra={t('label.currentPassword.extra', {
            date: dayjs().format('DD MMMM YYYY HH:mm'),
          })}
        >
          <span>********</span>
        </Form.Item>

        <ChangePasswordButtonWrapper>
          <Button type="primary" htmlType="button" style={{ textAlign: 'center' }} onClick={() => setVisible(true)}>
            {t('button')}
          </Button>
        </ChangePasswordButtonWrapper>
      </Form>

      <ChangePasswordModal
        formRef={formRef}
        loading={changePasswordResp.loading}
        visible={visible}
        onFinish={(data) => {
          changePassword({
            variables: data,
          })
        }}
        onCancel={() => setVisible(false)}
      />
    </>
  )
}

export default PasswordForm

const ChangePasswordButtonWrapper = styled.div`
  text-align: right;
`
