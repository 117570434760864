import React from 'react'
import { Form, Select } from 'antd'
import { useTranslation } from 'react-i18next'

import HooraySubmitButton from 'components/HooraySubmitButton'

import { commonFormProps, onFilterSelect } from 'utils/antd'

import type { LanguageAndRegionFormProps } from './interface'

const LanguageAndRegionForm: React.FC<LanguageAndRegionFormProps> = ({
  loading,
  languageAndRegionFormData,
  languageList,
  timezoneList,
  onFinish,
}) => {
  const { t } = useTranslation('languageAndRegion')

  return (
    <Form
      {...commonFormProps}
      name="generalForm"
      labelAlign="left"
      onFinish={onFinish}
      initialValues={languageAndRegionFormData}
    >
      <Form.Item colon={false} name="language" label={t('label.language.title')}>
        <Select
          showSearch
          filterOption={onFilterSelect}
          placeholder={t('global:selectPlaceholder', {
            placeholderName: t('label.language.title').toLowerCase(),
          })}
          allowClear
        >
          {Object.values(languageList).map((language) => {
            return (
              <Select.Option key={language.dataKey} value={language.dataKey}>
                {language.text}
              </Select.Option>
            )
          })}
        </Select>
      </Form.Item>

      <Form.Item colon={false} name="timezone" label={t('label.timezone.title')}>
        <Select
          showSearch
          filterOption={onFilterSelect}
          placeholder={t('global:selectPlaceholder', {
            placeholderName: t('label.timezone.title').toLowerCase(),
          })}
          allowClear
        >
          {Object.values(timezoneList).map((timezone) => {
            return (
              <Select.Option key={timezone.dataKey} value={timezone.dataKey}>
                {timezone.text}
              </Select.Option>
            )
          })}
        </Select>
      </Form.Item>

      <Form.Item>
        <HooraySubmitButton loading={loading} />
      </Form.Item>
    </Form>
  )
}

export default LanguageAndRegionForm
