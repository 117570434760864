import React from 'react'
import { Form, Input, Select } from 'antd'
import { useTranslation } from 'react-i18next'

import type { Rule } from 'rc-field-form/lib/interface'
import type { MasterDataSelection } from 'hooks/useMasterData/interface'

interface SystemFormInputProps {
  isEmailDisabled?: boolean
  namePrefixList: MasterDataSelection
}

const SystemFormInput: React.FC<SystemFormInputProps> = ({ isEmailDisabled, namePrefixList }) => {
  const { t } = useTranslation('systemForm')

  const ruleRequired: Rule = {
    required: true,
    message: t('global:requiredText'),
  }

  return (
    <>
      <Form.Item name="namePrefix" label={t('label.namePrefix.title')} rules={[ruleRequired]}>
        <Select
          placeholder={t('global:selectPlaceholder', {
            placeholderName: t('label.namePrefix.title').toLowerCase(),
          })}
        >
          {Object.values(namePrefixList).map((namePrefix) => {
            return (
              <Select.Option key={namePrefix.dataKey} value={namePrefix.dataKey}>
                {namePrefix.text}
              </Select.Option>
            )
          })}
        </Select>
      </Form.Item>

      <Form.Item name="firstName" label={t('label.firstName.title')} rules={[ruleRequired]}>
        <Input
          type="text"
          placeholder={t('global:inputPlaceholder', {
            placeholderName: t('label.firstName.title').toLowerCase(),
          })}
        />
      </Form.Item>

      <Form.Item name="lastName" label={t('label.lastName.title')} rules={[ruleRequired]}>
        <Input
          type="text"
          placeholder={t('global:inputPlaceholder', {
            placeholderName: t('label.lastName.title').toLowerCase(),
          })}
        />
      </Form.Item>

      <Form.Item name="email" label={t('label.email.title')} rules={[ruleRequired]}>
        <Input
          disabled={isEmailDisabled}
          type="text"
          placeholder={t('global:inputPlaceholder', {
            placeholderName: t('label.email.title').toLowerCase(),
          })}
        />
      </Form.Item>
    </>
  )
}

export default SystemFormInput
