import React from 'react'
import { Menu } from 'antd'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import FontawesomeIcon from './FontAwesomeIcon'

interface MenuConfig {
  key: string
  iconName: string
  linkTo: string
}

interface MenuListProps {
  defaultRoute: string
  currentRoute: string
}

const MenuList: React.FC<MenuListProps> = ({ currentRoute, defaultRoute }) => {
  const { t } = useTranslation('menu')

  return (
    <Menu mode="inline" theme="light" defaultSelectedKeys={[defaultRoute]} selectedKeys={[currentRoute]}>
      {menus.map(({ key, iconName, linkTo }) => (
        <Menu.Item key={key} icon={<FontawesomeIcon iconName={iconName} />}>
          {t(linkTo)}
          <Link to={linkTo} />
        </Menu.Item>
      ))}
    </Menu>
  )
}

const menus: MenuConfig[] = [
  {
    key: '0',
    iconName: 'user',
    linkTo: '/general',
  },
  {
    key: '1',
    iconName: 'key',
    linkTo: '/security',
  },
  {
    key: '2',
    iconName: 'cog',
    linkTo: '/setting',
  },
]

export default MenuList
