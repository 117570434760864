import { gql } from '@apollo/client'

const UPDATE_MY_PROFILE = gql`
  mutation updateMyProfile($input: INPUT_UPDATE_PROFILE) {
    updateMyProfile(input: $input) {
      message
      code
      payload {
        email {
          value
        }
        phone {
          code
          value
        }
        attribute
      }
    }
  }
`

export default UPDATE_MY_PROFILE
