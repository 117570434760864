import React from 'react'
import { Select } from 'antd'
import styled from 'styled-components'

import { onFilterSelect } from 'utils/antd'

import type { Role } from 'graphQL/useGetRoleRegistable/interface'

interface RoleSelectionProps {
  defaultRole?: Role
  roleList: Role[]
  onSelect: (value: string) => void
}

const RoleSelection: React.FC<RoleSelectionProps> = ({ defaultRole, roleList, onSelect }) => {
  return (
    <RoleSelectionWrapper>
      <Select
        showSearch
        filterOption={onFilterSelect}
        defaultValue={defaultRole?.roleKey}
        style={{ width: 120 }}
        onChange={onSelect}
      >
        {roleList.map((role) => (
          <Select.Option key={role.roleKey} value={role.roleKey}>
            {role.name}
          </Select.Option>
        ))}
      </Select>
    </RoleSelectionWrapper>
  )
}

export default RoleSelection

const RoleSelectionWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
