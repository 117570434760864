import React from 'react'
import { Layout, Typography } from 'antd'
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'

import { useThemeContext } from 'contexts/ThemeProvider/ThemeProvider'

import MenuList from 'components/Menu'
import MainMenu from 'components/MainMenu'
import UserMenu from 'components/UserMenu'
import Logo from './Logo'

import type { RouteMenuKey } from './interface'
import { appLocalApp } from 'utils/localService'

const defaultRoute = '0'

const routeMenukey: RouteMenuKey = {
  '/general': '0',
  '/security': '1',
  '/setting': '2',
}

const AuthenticatedLayout: React.FC = ({ children }) => {
  const theme = useThemeContext()

  const { pathname } = useLocation()
  const currentRoute = routeMenukey[pathname]

  const app = JSON.parse(appLocalApp.get() as string)

  const collapsed = theme.collapsed ? 1 : 0

  return (
    <ResponsiveLayout collapsed={collapsed}>
      <ResponsiveSider theme="light" collapsible collapsed={theme.collapsed} onCollapse={theme.collapseHandler}>
        <Logo />

        <MenuList defaultRoute={defaultRoute} currentRoute={currentRoute} />
      </ResponsiveSider>

      <Layout>
        <CustomHeader>
          <ResponsiveMenuTrigger>
            {React.createElement(theme.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
              className: 'trigger',
              onClick: theme.collapseHandler,
            })}

            <Typography.Title level={5} style={{ marginBottom: 0 }}>
              {app?.app.name}
            </Typography.Title>
          </ResponsiveMenuTrigger>

          <MainMenu defaultRoute={defaultRoute} currentRoute={currentRoute} />

          <UserMenu />
        </CustomHeader>

        <ResponsiveContent id="MainLayoutContent">{children}</ResponsiveContent>
      </Layout>
    </ResponsiveLayout>
  )
}

export default AuthenticatedLayout

const CustomHeader = styled(Layout.Header)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  background-color: #fff;
  padding-left: 24px;
  padding-right: 24px;
`

const ResponsiveLayout = styled(Layout)<{ collapsed: number }>`
  height: 100vh;
  transition: 0.2s;
  margin-left: ${(p) => (p.collapsed ? 80 : 200)}px;

  @media (max-width: 768px) {
    margin-left: 0;
  }
`

const ResponsiveSider = styled(Layout.Sider)`
  overflow: auto;
  height: 100vh;
  position: fixed;
  z-index: 20;
  left: 0;

  @media (max-width: 768px) {
    display: none;
  }
`

const ResponsiveContent = styled(Layout.Content)`
  padding: 24px;
  overflow: auto;

  @media (max-width: 768px) {
    padding: 16px;
  }
`

const ResponsiveMenuTrigger = styled.div`
  display: flex;
  align-items: center;

  & > :first-child {
    margin-right: 24px;
  }

  @media (max-width: 768px) {
    display: none;
  }
`
