import { gql } from '@apollo/client'

import { applicationSchema } from 'graphQL/schema'

const ROLE_REGISTABLE = gql`
  query getRoleRegisable($roleKey: String) {
    getRoleRegisable(roleKey: $roleKey) {
      payload {
        app {
          ${applicationSchema}
        }
        roleList {
          name
          roleKey
          attribute
          userField
          regisable
          roleType
          status
          isDefault
          isReplaceable
        }
      }
    }
  }
`

export default ROLE_REGISTABLE
