import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'

import { useAuthContext } from 'contexts/AuthProvider'

import useRefreshAccessToken from 'graphQL/useRefreshAccessToken'
import useGetMyProfile from 'graphQL/useGetMyProfile'

import { checkTokenExpired } from 'utils/token'
import { appLocalAccessToken, appLocalRefreshToken } from 'utils/localService'

import { routePaths } from 'pages/routeConfig'

const withAuth =
  <T extends object>(WrappedComponent: React.ComponentType<T>): React.FC<T> =>
  (props) => {
    const accessToken = appLocalAccessToken.get()
    const refreshToken = appLocalRefreshToken.get()

    const auth = useAuthContext()

    const [isAccessTokenExpired, setAccessTokenExpired] = useState(checkTokenExpired(accessToken))

    const shouldSkip = isAccessTokenExpired || !!auth.profilePictureFileKey
    const shouldRefreshToken = Boolean(isAccessTokenExpired && refreshToken)

    const [refreshAccessToken, refreshAccessTokenResp] = useRefreshAccessToken({
      onCompleted(resp) {
        auth.signIn(resp.refreshAccessToken.payload)

        setAccessTokenExpired(false)
      },
    })

    const myProfileQuery = useGetMyProfile({
      skip: shouldSkip,
      fetchPolicy: 'network-only',
      onCompleted(resp) {
        const myProfile = resp.getMyProfile.payload

        auth.signIn({
          accessToken: String(accessToken),
          refreshToken: String(refreshToken),
        })
        auth.onProfileImageChange(myProfile.attribute?.profilePictureFileKey)
      },
    })

    useEffect(() => {
      if (shouldRefreshToken) {
        refreshAccessToken({
          variables: {
            refreshToken: String(refreshToken),
          },
        })
      }
    }, [refreshAccessToken, isAccessTokenExpired, refreshToken, shouldRefreshToken])

    if (!(accessToken && refreshToken) && window.location.pathname !== routePaths.signIn) {
      return <Redirect to={routePaths.signIn} />
    }

    if (refreshAccessTokenResp.loading || myProfileQuery.loading) {
      return null
    }

    return <WrappedComponent {...props} />
  }

export default withAuth
