import React from 'react'
import { Collapse, List } from 'antd'
import styled from 'styled-components'
import { RightOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

import DeleteAccount from './DeleteAccount'

const DangerZone: React.FC = () => {
  const { t } = useTranslation('dangerZone')

  return (
    <DangerZoneCollapse
      defaultActiveKey={['1']}
      expandIcon={({ isActive }) => <RightOutlined style={{ color: '#a30404' }} rotate={isActive ? 90 : 0} />}
    >
      <Collapse.Panel header={<DangerZoneTitle className="bold">{t('title')}</DangerZoneTitle>} key="0">
        <List>
          <DeleteAccount t={t} />
        </List>
      </Collapse.Panel>
    </DangerZoneCollapse>
  )
}

export default DangerZone

const DangerZoneCollapse = styled(Collapse)`
  border: 1px solid #ff4d4f;

  & > .ant-collapse-item > .ant-collapse-header {
    display: flex;
    align-items: center;
  }
`

const DangerZoneTitle = styled.span`
  color: #ff4d4f;
`
