import React from 'react'
import { Button, ButtonProps } from 'antd'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

type TextAlignment = 'left' | 'center' | 'right'

interface HooraySubmitButtonProps extends ButtonProps {
  align?: TextAlignment
}

const HooraySubmitButton: React.FC<HooraySubmitButtonProps> = ({ align, ...props }) => {
  const { t } = useTranslation('global')

  return (
    <HooraySubmitButtonWrapper align={align}>
      <Button type="primary" htmlType="submit" {...props}>
        {t('global:saveButton')}
      </Button>
    </HooraySubmitButtonWrapper>
  )
}

export default HooraySubmitButton

const HooraySubmitButtonWrapper = styled.div<{ align?: TextAlignment }>`
  text-align: ${(p) => p.align || 'right'};
`
