import { gql } from '@apollo/client'

const VERIFY_EMAIL = gql`
  mutation verifyEmail($input: INPUT_VERIFY_EMAIL) {
    verifyEmail(input: $input) {
      message
      code
      payload {
        email {
          value
        }
        phone {
          code
          value
        }
        attribute
      }
    }
  }
`

export default VERIFY_EMAIL
