import React from 'react'

import GeneralFormInput from 'components/GeneralFormInput'

import type { GeneralFormProps } from './interface'

const GeneralForm: React.FC<GeneralFormProps> = ({ fieldList, masterDataList }) => {
  return <GeneralFormInput fieldList={fieldList} masterDataList={masterDataList} />
}

export default GeneralForm
