import React from 'react'
import { Button, Form, Input } from 'antd'

import type { Rule } from 'antd/lib/form'
import type { ResetPasswordFormProps } from './interface'

const ResetPasswordForm: React.FC<ResetPasswordFormProps> = ({ loading, onFinish }) => {
  const ruleRequired: Rule = {
    required: true,
    message: 'Required',
  }

  return (
    <Form name="resetPasswordForm" layout="vertical" labelAlign="left" onFinish={onFinish}>
      <Form.Item name="newPassword" label="Password" rules={[ruleRequired]}>
        <Input.Password placeholder="Enter your new password" />
      </Form.Item>

      <Form.Item name="confirmPassword" label="Confirm Password" rules={[ruleRequired]}>
        <Input.Password placeholder="Enter your new password again" />
      </Form.Item>

      <Button block loading={loading} type="primary" htmlType="submit">
        Reset Pasword
      </Button>
    </Form>
  )
}

export default ResetPasswordForm
