import React from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import DefaultLayout from 'layouts/DefaultLayout'

import useQueryString from 'hooks/useQueryString'

import SignInCard from 'components/SignInCard'

import { routePaths } from 'pages/routeConfig'

import { getAppDataFromStorage } from 'utils/app'

interface OnFinishData {
  email: string
}

const SignInPage: React.FC = () => {
  const { t } = useTranslation('signIn')

  const query = useQueryString()

  const history = useHistory()

  const onFinish = (value: OnFinishData) => {
    query.append('email', value.email)

    history.push({
      pathname: routePaths.applicationSignIn,
      search: query.toString(),
    })
  }

  return (
    <DefaultLayout platformName={getAppDataFromStorage().app?.name || 'Hooray.site'} isRootPage>
      <SignInCard
        title={t('title')}
        subtitle={t('email.title')}
        inputIcon="user"
        inputType="email"
        buttonName={t('email.button')}
        inputPlaceholder={t('global:inputPlaceholder', {
          placeholderName: t('email.name'),
        })}
        onFinish={onFinish}
      />
    </DefaultLayout>
  )
}

export default SignInPage
