import React, { useState } from 'react'
import { MenuUnfoldOutlined } from '@ant-design/icons'
import styled from 'styled-components'

import MainMenuDrawer from './MainMenuDrawer'

import type { MainMenuProps } from './interface'

const MainMenu: React.FC<MainMenuProps> = ({ currentRoute, defaultRoute }) => {
  const [visible, setVisible] = useState(false)

  return (
    <MainMenuWrapper>
      <MenuUnfoldOutlined onClick={() => setVisible(true)} style={{ cursor: 'pointer' }} />

      <MainMenuDrawer
        currentRoute={currentRoute}
        defaultRoute={defaultRoute}
        visible={visible}
        onClose={() => setVisible(false)}
      />
    </MainMenuWrapper>
  )
}

export default MainMenu

const MainMenuWrapper = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: block;
  }
`
