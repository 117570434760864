import { useMutation } from '@apollo/client'

import MUTATION_DOCUMENT from './verifyInviteOrganization'

import type { APIPayloadResponse, GraphQLServiceMutationHook } from 'graphQL/graphQL-service-hook'
import type { VerifyInviteOrganizationAPIPayload } from './interface'

interface VerifyInviteOrganizationData {
  verifyInviteOrganization: APIPayloadResponse<VerifyInviteOrganizationAPIPayload>
}

interface VerifyInviteOrganizationVars {
  code: string
}

const useVerifyInviteOrganization: GraphQLServiceMutationHook<
  VerifyInviteOrganizationData,
  VerifyInviteOrganizationVars
> = (options) => {
  return useMutation(MUTATION_DOCUMENT, {
    ...options,
  })
}

export default useVerifyInviteOrganization
