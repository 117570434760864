import React from 'react'

import Register from 'components/Register'

import { appLocalApp } from 'utils/localService'

const RegisterPage: React.FC = () => {
  const app = JSON.parse(appLocalApp.get() as string)

  return <Register app={app} />
}

export default RegisterPage
