import { useQuery } from '@apollo/client'
import { message } from 'antd'

import { getUserTypeEndpoint } from 'utils/user'
import { appLocalAccessToken } from 'utils/localService'

import QUERY_DOCUMENT from './getMasterData'

import type { APIPayloadResponse, FindDataInput, GraphQLServiceQueryHook } from 'graphQL/graphQL-service-hook'
import type { MasterDataAPIPayload } from './interface'

interface MasterData {
  getMasterData: APIPayloadResponse<MasterDataAPIPayload[]>
}

interface MasterDataVars {
  input?: FindDataInput
}

const useGetMasterData: GraphQLServiceQueryHook<MasterData, MasterDataVars> = (options) => {
  return useQuery(QUERY_DOCUMENT, {
    context: {
      uri: getUserTypeEndpoint(appLocalAccessToken.get()),
    },
    onError(error) {
      message.error(error.message)
    },
    ...options,
  })
}

export default useGetMasterData
