import React from 'react'
import { Card } from 'antd'
import styled from 'styled-components'

import type { ContentCardProps } from './interface'

const ContentCard: React.FC<ContentCardProps> = ({ loading, title, rightComponent, children }) => {
  return (
    <CardWrapper loading={loading} title={title} extra={rightComponent}>
      {children}
    </CardWrapper>
  )
}

export default ContentCard

const CardWrapper = styled(Card)`
  margin: 24px 0;
`
