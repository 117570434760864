import React from 'react'

import FullWidthSpace from 'components/FullWidthSpace'
import ImagePreview from 'components/ImagePreview'

import { getS3UrlByType } from 'utils/app'

import type { FileListProps } from './interface'

const FileList: React.FC<FileListProps> = ({ isEdit, fileList, onPreview, onRemove, children }) => {
  const s3Url = getS3UrlByType('PUBLIC')

  const fileListAsArray = Array.isArray(fileList) ? fileList : fileList && [fileList]

  return (
    <FullWidthSpace size={16} wrap align="start">
      {fileListAsArray &&
        fileListAsArray.length > 0 &&
        fileListAsArray.map((fileKey, index) => {
          const src = `${s3Url}/${fileKey}`

          return (
            <ImagePreview
              key={index}
              index={index}
              src={src}
              alt={src}
              isEdit={isEdit}
              onPreview={onPreview}
              onRemove={onRemove}
            />
          )
        })}
      {isEdit && children}
    </FullWidthSpace>
  )
}

export default FileList
