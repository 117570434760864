import React from 'react'
import { Form, Input } from 'antd'
import { useTranslation } from 'react-i18next'

import useQueryString from 'hooks/useQueryString'

import SystemFormInput from 'components/SystemFormInput'

import { queryKey, thirdPartyChannelType } from 'config'

import type { MasterDataSelection } from 'hooks/useMasterData/interface'
import type { Rule } from 'rc-field-form/lib/interface'

interface DefaultRegisterFormInputProps {
  email?: string | null
  namePrefix: MasterDataSelection
}

const DefaultRegisterFormInput: React.FC<DefaultRegisterFormInputProps> = ({ email, namePrefix }) => {
  const { t } = useTranslation('register')
  const query = useQueryString()

  const channelType = query.get(queryKey.channelType)

  const isThirdPartyRegister = channelType && thirdPartyChannelType.includes(channelType)

  const ruleRequired: Rule = {
    required: true,
    message: t('global:requiredText'),
  }

  return (
    <>
      <SystemFormInput isEmailDisabled={!!email} namePrefixList={namePrefix} />

      {!isThirdPartyRegister && (
        <>
          <Form.Item name="password" label={t('label.password')} rules={[ruleRequired]}>
            <Input.Password
              placeholder={t('global:inputPlaceholder', {
                placeholderName: t('label.password').toLowerCase(),
              })}
            />
          </Form.Item>
          <Form.Item name="confirmPassword" label={t('label.confirmPassword')} rules={[ruleRequired]}>
            <Input.Password
              placeholder={t('global:inputPlaceholder', {
                placeholderName: t('label.confirmPassword').toLowerCase(),
              })}
            />
          </Form.Item>
        </>
      )}
    </>
  )
}

export default DefaultRegisterFormInput
