import React from 'react'
import { Button, List } from 'antd'

import type { DeleteAccountProps } from './interface'

const DeleteAccount: React.FC<DeleteAccountProps> = ({ t }) => {
  return (
    <List.Item actions={[renderDeleteAccountButton()]}>
      <List.Item.Meta
        title={<span className="bold">{t('deleteAccount.title')}</span>}
        description={t('deleteAccount.description')}
      />
    </List.Item>
  )

  function renderDeleteAccountButton() {
    return (
      <Button type="primary" danger>
        {t('deleteAccount.button')}
      </Button>
    )
  }
}

export default DeleteAccount
