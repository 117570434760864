import { useMutation } from '@apollo/client'
import { message } from 'antd'

import MUTATION_DOCUMENT from './verifyEmail'

import type { APIPayloadResponse, GraphQLServiceMutationHook } from 'graphQL/graphQL-service-hook'
import type { MyProfileAPIPayload } from 'graphQL/useGetMyProfile/interface'
import type { VerifyEmailInput } from './interface'

interface VerifyEmailData {
  verifyEmail: APIPayloadResponse<MyProfileAPIPayload>
}

interface VerifyEmailVars {
  input: VerifyEmailInput
}

const useVerifyEmail: GraphQLServiceMutationHook<VerifyEmailData, VerifyEmailVars> = (options) => {
  return useMutation(MUTATION_DOCUMENT, {
    onError(error) {
      message.error(error.message)
    },
    ...options,
  })
}

export default useVerifyEmail
