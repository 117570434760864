import React from 'react'
import { Button, Card, Form, Input } from 'antd'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import FontawesomeIcon from './FontAwesomeIcon'

interface SignInCardProps {
  index?: string
  title: string
  subtitle: string
  inputPlaceholder?: string
  inputIcon: string
  inputType: string
  buttonName: string
  isSystemAdmin?: boolean
  email?: string
  buttonLoading?: boolean
  loading?: boolean
  onFinish?: (value: any) => void
  onForgotPassword?: (email: string) => void
}

const SignInCard: React.FC<SignInCardProps> = ({
  index,
  title,
  subtitle,
  email,
  inputIcon,
  inputType,
  inputPlaceholder,
  buttonName,
  isSystemAdmin,
  buttonLoading,
  loading,
  onFinish,
  onForgotPassword,
}) => {
  const { t } = useTranslation('signIn')

  return (
    <SignInCardWrapper loading={loading}>
      <Form onFinish={onFinish}>
        <Form.Item>
          <CardTitle className="bold">{title}</CardTitle>
        </Form.Item>

        <Form.Item>
          <CardSubtitle>{subtitle}</CardSubtitle>
        </Form.Item>

        <Form.Item name={inputType} rules={[{ required: true, message: t('global:requiredText') }]}>
          <Input
            id={index}
            type={inputType}
            placeholder={inputPlaceholder}
            prefix={<FontawesomeIcon iconName={inputIcon} />}
          />
        </Form.Item>

        {!isSystemAdmin && onForgotPassword && email && (
          <Form.Item>
            <span style={{ float: 'right', cursor: 'pointer' }} onClick={() => onForgotPassword(email)}>
              {t('forgotPasswordTitle')}
            </span>
          </Form.Item>
        )}

        <Form.Item>
          <Button block loading={buttonLoading} type="primary" htmlType="submit">
            {buttonName}
          </Button>
        </Form.Item>
      </Form>
    </SignInCardWrapper>
  )
}

export default SignInCard

const SignInCardWrapper = styled(Card)`
  width: 100%;
  border-color: var(--app-primaryColor);
`

const CardTitle = styled.span`
  font-size: 35px;
`

const CardSubtitle = styled.span`
  font-size: 20px;
  color: #7e7e7e;
`
