import { gql } from '@apollo/client'

import { loginSchema } from 'graphQL/schema'

const LOGIN_LINE = gql`
  mutation loginLine($lineToken: String!, $authType: ENUM_AUTH_TYPE!, $redirectUrl: String!) {
    loginLine(lineToken: $lineToken, authType: $authType, redirectUrl: $redirectUrl) {
      payload {
        ${loginSchema}
      }
    }
  }
`

export default LOGIN_LINE
