import React from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import styled, { createGlobalStyle } from 'styled-components'

import LoadingIcon from './LoadingIcon'

interface FullScreenLoadingProps {
  displayText?: boolean
  loadingText?: string
}

const FullScreenLoading: React.FC<FullScreenLoadingProps> = ({ displayText, loadingText }) => {
  const { t } = useTranslation('global')

  const shouldShowText = Boolean(loadingText || displayText)
  const loadingMessage = loadingText || t('loadingText')

  return createPortal(
    <>
      <GlobalStyle />

      <FullScreenLoadingWrapper>
        <LoadingIcon loadingText={shouldShowText ? loadingMessage : ''} />
      </FullScreenLoadingWrapper>
    </>,
    document.body
  )
}

export default FullScreenLoading

const GlobalStyle = createGlobalStyle`
  body {
    overflow: hidden;
  }
`

const FullScreenLoadingWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  background: #fff;
`
