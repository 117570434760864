import React from 'react'
import { Col, Row } from 'antd'

import LeftPanelSignIn from 'components/LeftPanelSignIn'
import RightPanelSignIn from 'components/RightPanelSignIn'
import LoadingIcon from 'components/LoadingIcon'

import { useThemeContext } from 'contexts/ThemeProvider/ThemeProvider'

import type { DefaultLayoutProps } from './interface'

const DefaultLayout: React.FC<DefaultLayoutProps> = ({ loading, platformName, isRootPage, children, extra }) => {
  const theme = useThemeContext()

  return (
    <Row>
      <Col xs={0} sm={0} md={0} lg={0} xl={14}>
        <LeftPanelSignIn
          title={theme.admin.text?.webTitle}
          subtitle={theme.admin.text?.authLeftSubTitle}
          description={theme.admin.text?.authLeftDescription}
          extra={extra}
        />
      </Col>
      <Col xs={24} sm={24} md={24} lg={24} xl={10}>
        <RightPanelSignIn isRootPage={isRootPage} title={platformName} copyright={theme.admin.text?.copyright}>
          {loading ? <LoadingIcon /> : children}
        </RightPanelSignIn>
      </Col>
    </Row>
  )
}

export default DefaultLayout
