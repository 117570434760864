import React, { useState } from 'react'
import { Button, Dropdown, Menu } from 'antd'
import { useTranslation } from 'react-i18next'

import { appLocalLocale, localeCode } from 'utils/localService'

import FontawesomeIcon from 'components/FontAwesomeIcon'
import FullScreenLoading from './FullScreenLoading'

const languages = {
  [localeCode.thTH]: { label: 'ไทย', value: localeCode.thTH },
  [localeCode.enUS]: { label: 'English', value: localeCode.enUS },
}

const LanguageSelector: React.FC = () => {
  const { t, i18n } = useTranslation('loading')

  const [isLanguageChanging, setLanguageChanging] = useState(false)

  const selectedLanguage = i18n.language

  return (
    <>
      <Dropdown overlay={renderLanguages} trigger={['click']}>
        <Button icon={<FontawesomeIcon iconName="globe" style={{ marginRight: 4 }} />}>
          {languages[selectedLanguage].label}
        </Button>
      </Dropdown>

      {isLanguageChanging && <FullScreenLoading loadingText={t('language')} />}
    </>
  )

  function renderLanguages() {
    return (
      <Menu selectedKeys={[selectedLanguage]}>
        {Object.values(languages).map((language) => (
          <Menu.Item
            key={language.value}
            onClick={() => {
              setLanguageChanging(true)

              appLocalLocale.set(language.value)
              i18n.changeLanguage(language.value)

              setTimeout(() => {
                window.location.reload()
              }, 2000)
            }}
          >
            {language.label}
          </Menu.Item>
        ))}
      </Menu>
    )
  }
}

export default LanguageSelector
