import React, { useCallback, useEffect, useState } from 'react'
import { Modal } from 'antd'
import styled from 'styled-components'

interface ImagePreviewModalProps {
  src: string
  visible: boolean
  onCancel: () => void
  minWidth: number
}

const _modalPadding = 24 * 2

const ImagePreviewModal: React.FC<ImagePreviewModalProps> = ({ src, visible, onCancel, minWidth }) => {
  const [modalWidth, setModalWidth] = useState(520)

  const setModalWidthWithPadding = useCallback(
    (elementWidth) => {
      const width = elementWidth + _modalPadding

      setModalWidth(width < minWidth ? minWidth : width)
    },
    [minWidth]
  )

  useEffect(() => {
    if (!src || !visible) {
      return
    }

    const img = new Image()

    img.src = src
    img.onload = () => {
      setModalWidthWithPadding(calculateWidthByScreen(img.width, img.height))
    }
  }, [src, visible, setModalWidthWithPadding])

  return (
    <Modal
      width={modalWidth}
      visible={visible}
      centered
      onCancel={onCancel}
      footer={null}
      closable={false}
      destroyOnClose
    >
      <Content>{renderModalContent()}</Content>
    </Modal>
  )

  function renderModalContent() {
    return <img src={src} alt="" />
  }

  function calculateWidthByScreen(elementWidth: number, elementHeight: number, scale = 80) {
    if (elementHeight > window.innerHeight) {
      const shouldHeight = (window.innerHeight / 100) * scale
      const ratio = (shouldHeight / elementHeight) * 100
      const widthByRatio = Math.round((elementWidth / 100) * ratio)

      return widthByRatio
    } else if (elementWidth > window.innerWidth) {
      return Math.round((window.innerWidth / 100) * scale)
    } else {
      return elementWidth
    }
  }
}

export default ImagePreviewModal

const Content = styled.div`
  text-align: center;

  > img {
    max-width: 100%;
  }
`
