import React, { useEffect, useState } from 'react'
import liff from '@line/liff'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

import useLoginLine from 'graphQL/useLoginLine'

import useQueryString from 'hooks/useQueryString'

import FullScreenLoading from 'components/FullScreenLoading'

import { useAuthContext } from 'contexts/AuthProvider'

import { liffEndpointUrl, liffId, queryKey } from 'config'

import { authorizationKey } from 'config'
import { routePaths } from 'pages/routeConfig'
import { clearLiffLocal } from 'utils/localService'

const LiffPage: React.FC = () => {
  const { t } = useTranslation('loading')
  const history = useHistory()

  const auth = useAuthContext()

  const query = useQueryString()
  const redirectUrl = query.get(authorizationKey.redirectUrl)
  const credentialKey = query.get(authorizationKey.credential)

  const [isLineLoggedIn, setIsLineLoggedIn] = useState(false)

  const [loginLine] = useLoginLine({
    onCompleted(resp) {
      const { token, authType, credentialKey, code, redirectUrl, isFirstLogin, profile } = resp.loginLine.payload

      auth.signIn(token)

      if (isFirstLogin) {
        query.append('email', profile.email[0].value)
        query.append('role_key', 'GUEST')
        query.append(authorizationKey.authType, 'AUTH_CODE')
        query.append(queryKey.channelType, 'LINE')

        history.push({
          pathname: routePaths.register,
          search: query.toString(),
        })
      } else {
        if (authType === 'AUTH_CODE') {
          const callbackUrl = `${redirectUrl}?${authorizationKey.code}=${code}&${authorizationKey.authType}=${authType}&${authorizationKey.credential}=${credentialKey}`

          window.open(callbackUrl, '_self')
        } else {
          history.push(routePaths.general)
        }
      }
    },
    onError() {
      clearLiffLocal()

      window.location.reload()
    },
    context: {
      headers: {
        credentialKey,
      },
    },
  })

  useEffect(() => {
    const onLineLogin = () => {
      setIsLineLoggedIn(true)

      const idToken = liff.getIDToken()

      if (!isLineLoggedIn) {
        loginLine({
          variables: {
            authType: 'AUTH_CODE',
            lineToken: String(idToken),
            redirectUrl: String(redirectUrl),
          },
        })
      }
    }

    liff
      .init({
        liffId,
      })
      .then(() => {
        if (!liff.isLoggedIn()) {
          liff.login({
            redirectUri: `${liffEndpointUrl}/liff?${authorizationKey.credential}=${credentialKey}&${authorizationKey.redirectUrl}=${redirectUrl}&${authorizationKey.authType}=AUTH_CODE`,
          })
        } else {
          onLineLogin()
        }
      })
      .catch((err) => {
        throw err
      })
  }, [isLineLoggedIn, history, loginLine, redirectUrl, credentialKey])

  return <FullScreenLoading loadingText={isLineLoggedIn ? t('signIn') : t('lineLogin')} />
}

export default LiffPage
