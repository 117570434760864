import React from 'react'
import { Form, Input, Select } from 'antd'
import { useTranslation } from 'react-i18next'

import DatePicker from './DatePicker'
import PhoneNumber from './PhoneNumberInput'

import type { UserField } from 'graphQL/useGetRoleRegistable/interface'
import type { Rule } from 'rc-field-form/lib/interface'
import type { MasterDataList } from 'hooks/useMasterData/interface'

interface GeneralFormInputProps {
  fieldList: UserField[]
  masterDataList: MasterDataList
}

const GeneralFormInput: React.FC<GeneralFormInputProps> = ({ fieldList, masterDataList }) => {
  const { t } = useTranslation('global')

  const ruleRequired: Rule = {
    required: true,
    message: t('global:requiredText'),
  }

  return (
    <>
      {fieldList?.map((field, index) => {
        switch (field.type) {
          case 'TEXT':
            return (
              <Form.Item
                key={`${field.key}-${index}`}
                name={field.key}
                label={field.title}
                rules={[
                  {
                    ...ruleRequired,
                    required: field.required,
                  },
                ]}
              >
                <Input placeholder={field.placeholder} />
              </Form.Item>
            )
          case 'DATE':
            return (
              <Form.Item
                key={`${field.key}-${index}`}
                name={field.key}
                label={field.title}
                rules={[
                  {
                    ...ruleRequired,
                    required: field.required,
                  },
                ]}
              >
                <DatePicker style={{ width: '100%' }} placeholder={field.placeholder} />
              </Form.Item>
            )
          case 'PHONE_NUMBER':
            return (
              <Form.Item key={`${field.key}-${index}`} label={field.title}>
                <PhoneNumber field={field} dialingCodeList={masterDataList.DIALING_CODE} />
              </Form.Item>
            )
          case 'OPTION':
            if (field.dataKey) {
              const dataKey = Object.values(masterDataList[field.dataKey])

              if (field.optionList?.length === 0 || dataKey.length === 0) {
                return null
              }

              return (
                <Form.Item
                  key={`${field.key}-${index}`}
                  name={field.key}
                  label={field.title}
                  rules={[
                    {
                      ...ruleRequired,
                      required: field.required,
                    },
                  ]}
                >
                  <Select placeholder={field.placeholder}>
                    {field.optionType === 'MASTER_DATA'
                      ? dataKey.map((key) => {
                          return (
                            <Select.Option key={key.dataKey} value={key.dataKey}>
                              {key.text}
                            </Select.Option>
                          )
                        })
                      : field.optionList?.map((option) => {
                          return (
                            <Select.Option key={option.value} value={option.value}>
                              {option.value}
                            </Select.Option>
                          )
                        })}
                  </Select>
                </Form.Item>
              )
            }

            return null
        }
      })}
    </>
  )
}

export default GeneralFormInput
