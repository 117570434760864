import { Popconfirm } from 'antd'
import React from 'react'
import styled from 'styled-components'
import FontAwesomeIcon from './FontAwesomeIcon'

interface ImageOrVideoPreviewProps {
  index: number
  src: string
  alt: string
  isEdit?: boolean
  onRemove?: (index: number) => void
  onPreview?: (src: string) => void
}

const ImagePreview: React.FC<ImageOrVideoPreviewProps> = ({ index, src, alt, isEdit, onRemove, onPreview }) => {
  return (
    <ImagePreviewWrapper>
      <FileDisplayWrapper>
        <Img width="100%" src={src} alt={alt} />
      </FileDisplayWrapper>

      <ActionOverlay>
        <ActionOverlayContent>
          {isEdit && (
            <Popconfirm title="คุณแน่ใจที่จะลบไหม?" onConfirm={() => onRemove?.(index)}>
              <IconContent>
                <FontAwesomeIcon iconName="trash" style={{ color: '#fff' }} />
              </IconContent>
            </Popconfirm>
          )}
          <IconContent onClick={() => onPreview?.(src)}>
            <FontAwesomeIcon iconName="eye" style={{ color: '#fff' }} />
          </IconContent>
        </ActionOverlayContent>
      </ActionOverlay>
    </ImagePreviewWrapper>
  )
}

export default ImagePreview

const ImagePreviewWrapper = styled.div`
  display: block;
  margin: auto 0;
  position: relative;
  max-width: 150px;
  min-width: 150px;
  max-height: 150px;
  min-height: 150px;
  overflow: hidden;
`

const FileDisplayWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`

const Img = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const ActionOverlayContent = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  text-align: center;
  opacity: 0;
  transform: translateY(-50%);
  transition: 0.2s;
`

const ActionOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0);
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: rgba(0, 0, 0, 0.4);

    ${ActionOverlayContent} {
      opacity: 1;
    }
  }
`

const IconContent = styled.span`
  display: inline-block;
  margin: 0 16px;
  font-size: 20px;
  color: white;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    color: orange;
  }
`
