import React, { useState, useEffect } from 'react'
import { Button, Result } from 'antd'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import useQueryString from 'hooks/useQueryString'

import FullScreenLoading from 'components/FullScreenLoading'

import useVerifyInviteOrganization from 'graphQL/useVerifyInviteOrganization'

import { routePaths } from 'pages/routeConfig'
import { authorizationKey } from 'config'

import type { ApolloError } from '@apollo/client'
import type { ResultResponse } from './interface'

const VerifyInviteOrganizationPage: React.FC = () => {
  const { t } = useTranslation('verifyInviteOrganization')

  const [isInviteSuccess, setIsInviteSuccess] = useState(false)
  const [isInviteFailed, setIsInviteFailed] = useState(false)
  const [error, setError] = useState<ApolloError | undefined>()

  const query = useQueryString()
  const history = useHistory()

  const code = query.get(authorizationKey.code)
  const credentialKey = query.get(authorizationKey.credential)

  const [verifyInviteOrganization] = useVerifyInviteOrganization({
    onCompleted(resp) {
      if (resp.verifyInviteOrganization.payload.registered === 'YES') {
        setIsInviteSuccess(true)
        setIsInviteFailed(false)
        setError(undefined)
      } else {
        query.append(authorizationKey.authType, 'AUTH_CODE')

        history.push({
          pathname: routePaths.register,
          search: query.toString(),
        })
      }
    },
    onError(error) {
      setError(error)
      setIsInviteSuccess(false)
      setIsInviteFailed(true)
    },
    context: {
      headers: {
        credentialKey,
      },
    },
  })

  const { status, subTitle, title } = useResult()

  useEffect(() => {
    if (code) {
      verifyInviteOrganization({
        variables: {
          code,
        },
      })
    } else {
      history.push(routePaths.signIn)
    }
  }, [code, history, verifyInviteOrganization])

  if (isInviteSuccess || isInviteFailed) {
    return (
      <VerifySuccessWrapper>
        <Result
          status={status}
          title={title}
          subTitle={subTitle}
          extra={
            status === 'success' && [
              <Button type="primary" key="sign-in" onClick={() => history.push(routePaths.signIn)}>
                Back to sign in
              </Button>,
            ]
          }
        />
      </VerifySuccessWrapper>
    )
  }

  return <FullScreenLoading loadingText={t('loading')} />

  function useResult(): ResultResponse {
    if (isInviteSuccess) {
      return {
        status: 'success',
        title: t('success.title'),
        subTitle: t('success.subtitle'),
      }
    }

    return {
      status: 'error',
      title: t('failed.title'),
      subTitle: error?.message,
    }
  }
}

export default VerifyInviteOrganizationPage

const VerifySuccessWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`
