import React from 'react'
import { Button, Form, Input } from 'antd'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import DefaultLayout from 'layouts/DefaultLayout'

import FontawesomeIcon from 'components/FontAwesomeIcon'
import BackButton from 'components/BackButton'
import FullWidthSpace from 'components/FullWidthSpace'
import CredentialApplicationSignInCard from './CredentialApplicationSignInCard'

import { routePaths } from 'pages/routeConfig'

import type { CredentialApplicationSignInProps } from './interface'

const CredentialApplicationSignIn: React.FC<CredentialApplicationSignInProps> = ({
  app,
  isResetPasswordDisabled,
  isShowForgotPasswordForm,
  loginEmailLoading,
  resetPasswordLoading,
  onFinish,
  onFinishResetPassword,
  onForgotPassword,
  onBackToSignInApplication,
}) => {
  const { t } = useTranslation('credentialApplicationSignIn')
  const history = useHistory()

  return (
    <DefaultLayout isRootPage platformName={app?.app.name || t('platform:title')} extra={renderRegisterRoute()}>
      {isShowForgotPasswordForm && <BackButton onBack={onBackToSignInApplication} />}
      {isShowForgotPasswordForm ? (
        renderForgotPasswordForm()
      ) : (
        <CredentialApplicationSignInCard
          onFinish={onFinish}
          onForgotPassword={onForgotPassword}
          buttonLoading={loginEmailLoading}
        />
      )}
    </DefaultLayout>
  )

  function renderForgotPasswordForm() {
    return (
      <Form onFinish={onFinishResetPassword}>
        <FullWidthSpace size={16} direction="vertical">
          <Form.Item name="email" rules={[{ required: true, message: t('global:requiredText') }]}>
            <Input
              type="email"
              placeholder={t('global:inputPlaceholder', {
                placeholderName: t('label.email'),
              })}
              prefix={<FontawesomeIcon iconName="user" />}
            />
          </Form.Item>

          <Form.Item>
            <Button
              block
              loading={resetPasswordLoading}
              disabled={isResetPasswordDisabled}
              type="primary"
              htmlType="submit"
            >
              {t('button.resetPassword')}
            </Button>
          </Form.Item>
        </FullWidthSpace>
      </Form>
    )
  }

  function renderRegisterRoute() {
    return (
      <RegisterWrapper>
        <RegisterTitle className="medium">{t('registerTitle')}</RegisterTitle>
        <Button
          type="primary"
          onClick={() =>
            history.push({
              pathname: routePaths.register,
              search: window.location.search,
            })
          }
        >
          {t('button.register')}
        </Button>
      </RegisterWrapper>
    )
  }
}

export default CredentialApplicationSignIn

const RegisterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const RegisterTitle = styled.span`
  font-size: 16px;
  margin-bottom: 32px;
`
